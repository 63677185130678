<template>
  <div class="breadcrumbs">
    <div v-for="(documentType, index) in documentTypes"
         :key="index"
         :class="{
           item: true,
           done: index < current,
           active: index === current
         }"
         @click="select(index)">
      <div class="spanner"></div>
      <span class="label">{{ translate(`DOCUMENT_TYPE.${documentType}`) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {translate} from '@/i18n';
import {checkIfInIframe} from "@/utils/frameUtils";

export default defineComponent({
  name: 'Breadcrumbs',
  props: {
    documentTypes: {
      type: Array,
      required: true
    },
    current: {
      type: Number,
      default: () => 0,
    },
  },
  emits: ['input'],
  model: {
    prop: 'current',
    event: 'input',
  },
  methods: {
    translate,
    select(index: number) {
      if (index < this.current) {
        this.$emit('input', index);
      }
    },
  },
  mounted() {
    checkIfInIframe();
  }
});
</script>

<style scoped lang="less">
@import "/src/assets/css/main.less";

body.in-iframe .breadcrumbs {
  width: 60%;

  .item {
    &.active {
      .label {
        font-size: 0.8rem;
      }
    }
  }

  .spanner {
    height: .3em;
  }
}

.breadcrumbs {
  width: 100%;
  display: flex;
  flex-flow: row;

  .item {
    flex: 1;
    margin: 8px 2px;

    &.done {
      .spanner {
        background-color: lighten(@primary-color, 20%);
      }
    }

    &.active {
      flex: 2;

      .spanner {
        background-color: @primary-color;
      }
      .label {
        display: block;
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .spanner {
      width: 100%;
      height: .6em;
      background-color: @default-color;
    }

    .label {
      font-size: 0.7rem;
      line-height: 18px;
      text-align: center;
    }
  }
}
</style>
