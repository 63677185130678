<template>
  <p :class="{ 'document-counter': true}">
    {{ this.value }} {{ translate(`UPLOAD.COUNT.${value > 1 ? 'PLURAL' : 'SINGULAR'}`) }}
  </p>
</template>

<script>
import {translate} from "@/i18n";
import {checkIfInIframe} from "@/utils/frameUtils";

export default {
  methods: {translate},
  props: {
    value: {
      type: Number,
      default: () => 0,
    },
  },
  mounted() {
    checkIfInIframe();
  }
};
</script>

<style scoped lang="less">

body.in-iframe .document-counter {
  font-size: 1em;
}

.document-counter {
  margin-bottom: 0;
  &.hidden {
    visibility: hidden;
  }
}
</style>
