<template>
  <p class="proof" v-if="isVisible" :style="proofClass()">
    {{ this.printProof }}
  </p>
</template>

<script>
export default {
  name: 'ProofPrinter',
  props: {
    proof: { type: Object, default: null },
  },
  computed: {
    printProof() {
      if (this.proof.status === 'OK') {
        return 'Les documents sont téléversés.';
      }
      return 'Erreur lors du téléversement.\nEssayez de le numériser à nouveau avant de le téléverser.\n';
    },
    isVisible() {
      return this.proof != null;
    },
  },
  methods: {
    proofClass() {
      if (this.proof.status === 'ERROR') {
        return 'color: #FF8019; font-weight: bold;';
      } return 'color: #2c3e50;';
    },
  },
};
</script>

<style scoped>
.proof{
  justify-self: center;
}
</style>