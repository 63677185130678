import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';

import Upload from '../views/Upload.vue';
import NotFound from "../views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
