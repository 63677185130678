import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n'
import {ApmVuePlugin} from '@elastic/apm-rum-vue'
import {APM_ENABLED, DOPPLER_ENVIRONMENT} from '@/env';


require('@/assets/css/main.less');


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

createApp(App).use(i18n).use(Toast).use(router).mount('#app')
    /*.use(ApmVuePlugin, {
    router,
    config: {
        serviceName: 'UPLOAD_FRONTEND',
        serverUrl: 'https://apm.getmeelo.com',
        active: APM_ENABLED,
        environment: DOPPLER_ENVIRONMENT,
    }
})*/;