<template>
  <form class="uploader" @dragover.prevent="onDragOver" @dragleave="onDragLeave" @drop.prevent="onDrop">
    <label for="file-uploader">
      <CircleMessage :icon="documentType" :uploading="uploading">
        {{ translate('UPLOAD.MESSAGE') }}<br/>{{ translate(`DOCUMENT_TYPE.${documentType}`).toLowerCase() }}
      </CircleMessage>
    </label>
    <button class="primary shadowed"
            :disabled="disabled"
            v-on:click="onCapture">{{ translate('UPLOAD.CAPTURE') }}
    </button>
    <input ref="file_uploader"
           id="file-uploader"
           type="file"
           required
           accept="image/*,application/pdf"
           :disabled="disabled"
           multiple="multiple"
           v-on:input="onChange"
           :maxlength="maxUploadSize"
    />
    <slot/>
  </form>
</template>

<script>
import CircleMessage from '@/components/circleMessage/CircleMessage.vue';
import {translate} from "@/i18n";
import {checkIfInIframe} from "@/utils/frameUtils";


export default {
  name: 'Uploader',
  components: {CircleMessage},
  props: ['documentType', 'disabled', 'uploading', 'maxUploadSize'],
  emits: ['upload'],
  computed: {
  },
  methods: {
    translate,
    onCapture(event) {
      event.preventDefault();
      this.$refs.file_uploader.click();
    },
    onChange({target: {files}}) {
      this.onMultipleUpload(files);
      this.$refs.file_uploader.value = '';
    },
    onMultipleUpload(files) {
      this.$emit('uploads', files);
    },
    onDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    onDragLeave(event) {
      event.preventDefault();
    },
    onDrop(event) {
      const files = event.dataTransfer.files;
      this.onMultipleUpload(files);
    },
  },
  mounted() {
    checkIfInIframe();

      window.addEventListener('beforeunload', (event) => {
        if (this.uploading) {
          event.preventDefault();
          event.returnValue = '';
        }
      });

  }
};
</script>

<style scoped lang="less">

body.in-iframe form.uploader {
  width: 0;

  button {
    font-size: 0.8em;
  }
}

form.uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 1em;

  label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 1.2em;
  }

  input[type=file] {
    display: none;
  }
}
</style>