<template>
  <div class="SuccessCircleMessage">
    <CircleMessage icon="CHECK">
      <h2>{{ translate('THANKS') }}</h2>
      <span>{{ translate('UPLOAD.DOCUMENTS_UPLOADED') }}</span>
    </CircleMessage>
    <button class="primary" v-if="backButton" v-on:click="$emit('back')">{{ translate('UPLOAD.CONTINUE_UPLOAD') }}</button>
  </div>
</template>
<script lang="ts">
import CircleMessage from '@/components/circleMessage/CircleMessage.vue';
import {translate} from '@/i18n';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SuccessCircleMessage',
  methods: {translate},
  props: ['backButton'],
  components: {CircleMessage},
  emits: ['back'],
});

</script>
<style lang="less" scoped>
.SuccessCircleMessage {
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 1em;
}
</style>
