<template>
  <div id="app" class="page">
    <router-view/>
  </div>
</template>

<script>
import {checkIfInIframe} from "@/utils/frameUtils";

export default {
  name: 'App',
  mounted() {
    checkIfInIframe();
  },
}
</script>

<style lang="less">

body {
  margin: 0;
}

body.in-iframe #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>