import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "breadcrumbs" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentTypes, (documentType, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass({
           item: true,
           done: index < _ctx.current,
           active: index === _ctx.current
         }),
        onClick: ($event: any) => (_ctx.select(index))
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "spanner" }, null, -1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate(`DOCUMENT_TYPE.${documentType}`)), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}