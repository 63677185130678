<template>
  <div :class="`circle-message ${uploading && 'uploading'}`">
    <div class="message">
      <slot />
    </div>
    <img v-if="iconSrc"
         class="icon"
         :src="iconSrc"
         alt="icon" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import {checkIfInIframe} from "@/utils/frameUtils";

const ICONS_URL = {
  IDENTITY: require('@/assets/icons/id-card-svgrepo-com.svg'),
  DRIVING_LICENSE: require('@/assets/icons/driver-license-car-svgrepo-com.svg'),
  TAX_REPORT: require('@/assets/icons/report-svgrepo-com.svg'),
  CHECK: require('@/assets/icons/check-tick-svgrepo-com.svg'),
  CROSS: require('@/assets/icons/cross-svgrepo-com.svg'),
  OTHER: require('@/assets/icons/report-svgrepo-com.svg'),
};

export default {
  props: {
    icon: {
      type: String,
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconSrc() {
      if (this.icon) {
        return ICONS_URL[this.icon] || ICONS_URL.OTHER;
      }
      return null;
    },
  },
  mounted() {
  checkIfInIframe();
  }
};
</script>

<style scoped lang="less">

body.in-iframe .circle-message {
  width: 11.25rem;
  .message {
    font-size: 0.8rem;
  }
  img {
    opacity: .6;
    margin: 0 3em;
    height: 12%;
  }
}

.circle-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  min-width: 100px;
  max-width: 400px;
  width: 90%;

  background-image: url('~@/assets/images/background-circle.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  .message {
    font-size: 1.5em;
  }
  img {
    opacity: .6;
    margin: 0 3em;
    height: 30%;
  }

  @keyframes uploading {
    from { background-size: 100%; }
    to { background-size: 0; }
  }

  &.uploading, .uploading & {
    animation-name: uploading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}
</style>
