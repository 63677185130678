import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // @ts-ignore
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

function computeLocale() {
  const locales = ['fr-FR', 'fr', 'en-US', 'en-GB', 'en'];
  if (!locales.includes(navigator.language)) {
    return 'en';
  }
  return navigator.language.substring(0, 2);
}

const locale = computeLocale();

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale,
  fallbackLocale: locale,
  messages: loadLocaleMessages(),
});

export const translate = (key: string) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key);
};

export default i18n;
