<template>
  <CircleMessage icon="CROSS">
      <h3>{{ translate('OOPS') }}</h3>
      <span>{{ translate('ERROR.UNKNOWN') }}</span>
  </CircleMessage>
</template>
<script lang="ts">
import CircleMessage from '@/components/circleMessage/CircleMessage.vue';
import {translate} from '@/i18n';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ErrorCircleMessage',
  methods: {translate},
  components: {CircleMessage}
});
</script>

