import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "unauthorized",
  class: "page"
}
const _hoisted_2 = {
  key: 1,
  id: "upload",
  class: "page"
}
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled", "title"]
const _hoisted_7 = {
  key: 0,
  class: "max-upload-size"
}
const _hoisted_8 = {
  key: 1,
  class: "max-files-length"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleMessage = _resolveComponent("CircleMessage")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Uploader = _resolveComponent("Uploader")!
  const _component_DocumentCounter = _resolveComponent("DocumentCounter")!
  const _component_SuccessCircleMessage = _resolveComponent("SuccessCircleMessage")!
  const _component_ErrorCircleMessage = _resolveComponent("ErrorCircleMessage")!

  return (_ctx.isError)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_CircleMessage, null, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.translate('OOPS')) + "...", 1),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.show === 'UPLOAD')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_Breadcrumbs, {
                current: _ctx.documentTypeIndex,
                documentTypes: _ctx.documentTypes
              }, null, 8, ["current", "documentTypes"]),
              _createVNode(_component_Uploader, {
                onUploads: _ctx.uploads,
                "document-type": _ctx.documentType,
                uploading: _ctx.loading,
                "max-upload-size": _ctx.maxFilesToUpload
              }, null, 8, ["onUploads", "document-type", "uploading", "max-upload-size"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("button", {
                  id: "previous-button",
                  class: "shadowed",
                  disabled: _ctx.loading || _ctx.documentTypeIndex === 0,
                  onClick: _cache[0] || (_cache[0] = () => _ctx.documentTypeIndex -= 1)
                }, _toDisplayString(_ctx.translate('PREVIOUS')), 9, _hoisted_4),
                (_ctx.documentTypeIndex < _ctx.documentTypes.length - 1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      id: "next-button",
                      class: "shadowed",
                      disabled: _ctx.loading,
                      onClick: _cache[1] || (_cache[1] = () => _ctx.documentTypeIndex+=1)
                    }, _toDisplayString(_ctx.translate('NEXT')), 9, _hoisted_5))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      id: "end-button",
                      class: "shadowed primary",
                      disabled: _ctx.loading || !_ctx.allDocumentTypesAreFilled,
                      title: !_ctx.allDocumentTypesAreFilled ? _ctx.translate('UPLOAD.MISSING_DOCUMENTS') : '',
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.doOcr && _ctx.doOcr(...args)))
                    }, _toDisplayString(_ctx.translate('END')), 9, _hoisted_6))
              ]),
              _createVNode(_component_DocumentCounter, { value: _ctx.uploadCount }, null, 8, ["value"]),
              (_ctx.multipartProperties)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.translate('UPLOAD.MAX_UPLOAD_SIZE')) + " " + _toDisplayString(_ctx.multipartProperties.pretty), 1))
                : _createCommentVNode("", true),
              (_ctx.maxRequestSize)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.translate('UPLOAD.MAX_FILES_LENGTH')) + " " + _toDisplayString(_ctx.maxFilesToUpload), 1))
                : _createCommentVNode("", true)
            ], 64))
          : (_ctx.show === 'SUCCESS')
            ? (_openBlock(), _createBlock(_component_SuccessCircleMessage, {
                key: 1,
                onBack: _cache[3] || (_cache[3] = ()=>{
      _ctx.ocrDone = false;
      _ctx.documentTypeIndex = 0;
    }),
                "back-button": !_ctx.blocking
              }, null, 8, ["back-button"]))
            : (_ctx.show === 'ERROR' && !_ctx.isError  && _ctx.task !== null)
              ? (_openBlock(), _createBlock(_component_ErrorCircleMessage, { key: 2 }))
              : _createCommentVNode("", true)
      ]))
}