import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "SuccessCircleMessage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleMessage = _resolveComponent("CircleMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CircleMessage, { icon: "CHECK" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.translate('THANKS')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.translate('UPLOAD.DOCUMENTS_UPLOADED')), 1)
      ]),
      _: 1
    }),
    (_ctx.backButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, _toDisplayString(_ctx.translate('UPLOAD.CONTINUE_UPLOAD')), 1))
      : _createCommentVNode("", true)
  ]))
}